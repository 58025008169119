<template>
    <div class='app-container'>
        <el-row :gutter="10">
            <el-col :span="12">
                <el-card class="left reviewCustom-box-card">
                    <div slot="header">
                        <el-input placeholder="请输入" v-model="search" size='small' style="width:300px">
                        </el-input>
                        <el-button type="success" size="mini" icon="el-icon-plus" style="float:right;margin:3px 0px;"
                            @click="insertReviewHandle">
                            添加
                        </el-button>
                    </div>
                    <el-table :data="reviewList.filter(data=>!search || data.patternName.includes(search))"
                        class="mytable-scrollbar" @row-click='reviewRowClickHandle' stripe style="width: 100%"
                              @current-change="handleCurrentChange"
                              highlight-current-row
                              :current-row="currentRow">
                        <el-table-column prop="patternName" label="名称" min-width="400" :show-overflow="'tooltip'">
                        </el-table-column>
                        <el-table-column prop="isSignature" label="电子签名" width="200">
                            <template slot-scope="scope">
                                <div @click.stop="changeSignature(scope.row)">
                                    <vxe-switch v-model="scope.row.isSignature" open-label="是" close-label="否">
                                    </vxe-switch>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="200">
                            <template slot-scope="scope">
                                <el-button size="mini" type="text" @click='changeReviewInfoHandle(scope.row.id)'>评审信息维护
                                </el-button>
                                <!-- <el-button size="mini" type="text">编辑</el-button> -->
                                <el-popconfirm title="这是一段内容确定删除吗？" @confirm='postDisablePattern(scope.row.id)'>
                                    <el-button slot="reference" size="mini" type="text" style="margin-left:10px;">删除
                                    </el-button>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <vxe-modal v-model="insertReviewModel" :position="{top: '0px'}" width="500" :show-footer='true'
                    show-zoom resize :transfer='true'>
                    <template #title>
                        添加评审体系
                    </template>
                    <vxe-form :data="insertReviewData" title-align="right" title-width="100">
                        <vxe-form-item field="name" title="评审体系名称" :span="20" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-input v-model='data.name' placeholder='请输入' size="small"></vxe-input>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="isCopy" title="是否复制模板" :span="20" :item-render="{}">
                            <template #default="{ data }">
                                <vxe-radio-group v-model="data.isCopy">
                                    <vxe-radio :label="1" content="是"></vxe-radio>
                                    <vxe-radio :label="0" content="否"></vxe-radio>
                                </vxe-radio-group>
                            </template>
                        </vxe-form-item>
                        <vxe-form-item field="patternId" title="选择模板" v-if="insertReviewData.isCopy == 1" :span="20"
                            :item-render="{}">
                            <template #default="{ data }">
                                <el-select v-model="data.patternId" style="width:100%" filterable size="small"
                                    placeholder="请选择">
                                    <el-option v-for="item in reviewList" :key="item.id" :label="item.patternName"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                        </vxe-form-item>
                    </vxe-form>
                    <template #footer>
                        <el-button @click="cfmInsertReivewNameHandle" type="primary" size="small">确认</el-button>
                    </template>
                </vxe-modal>
            </el-col>
            <el-col :span="12">
                <el-card class="right reviewCustom-box-card">
                    <!-- 右边 -->
                    <vxe-table border align="center" ref="dataTable" :cell-style='cellStyleNumber'
                        :cell-class-name="cellClassName" style="width: 100%;" :span-method="mergeMethod"
                        :data="dataTable" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
                        <vxe-column field="firstIndex" title="指标" width="30%">
                            <template #default="{row}">
                                <template v-if="row.type == '4'">
                                    <!-- 总分 -->
                                    <div v-html="row.firstIndex + '（满分 ' + row.score + ' 分）'"> </div>
                                    <!-- {{row.firstIndex}}（{{row.score}} 分） -->
                                </template>
                                <template v-else-if="row.type == '6'">
                                    <!-- 注释 -->
                                    <div class="txtareaCom" v-html="row.description">
                                        <!-- {{row.comment}} -->
                                    </div>
                                </template>
                                <template v-else-if="row.type == '5'">
                                    <!-- 文本型 -->
                                    <div class="txtareaCom">
                                        <span style="color:#000">{{row.firstIndex}}</span><span
                                            v-if="row.description">{{'（'+row.description + '）'}}</span>
                                    </div>
                                    <el-input type="textarea" class="txtarea"
                                        :placeholder="'字数限制：'+row.unselectedMark+'-'+row.selectedMark" hidden
                                        :autosize='{ minRows: 15 }' v-model="row.content" :maxlength="row.selectedMark"
                                        :minlength="row.unselectedMark" :show-word-limit='true'>
                                    </el-input>
                                </template>
                                <template v-else>
                                    <!-- 数值 -->
                                    <div v-html="row.firstIndex"></div>
                                    <!-- {{row.firstIndex}} -->
                                </template>
                            </template>
                        </vxe-column>
                        <vxe-column field="secondIndex" title="评价要素" align="left" width="50%">
                            <template #default="{row}">
                                <template v-if="row.type == '2'">
                                    <!-- 单选 -->
                                    <vxe-radio v-model="singleSelects[row.group]" class="tableRadio" :label="row.score"
                                        :content="content(row.score,row.leftRange,row.rightRange)"
                                        :disabled='row.rightRange && !(row.leftRange<=totalTypeTable[0].content && totalTypeTable[0].content<=row.rightRange)'>
                                    </vxe-radio>
                                    <div v-if="row.description" v-html="row.description">
                                        <!-- {{row.description}} -->
                                    </div>
                                </template>
                                <template v-else-if="row.type == '3'">
                                    <!-- 单选 -->
                                    <vxe-checkbox v-model="row.content" :content="row.score"></vxe-checkbox>
                                    <div v-if="row.description" v-html="row.description">
                                        <!-- {{row.comment}} -->
                                    </div>
                                </template>
                                <template v-else>
                                    <!-- 数值 -->
                                    <div v-html="row.secondIndex + '（满分 ' + row.score + ' 分）'"></div>
                                    <!-- {{row.secondIndex}} （{{row.score}} 分） -->
                                </template>
                            </template>
                        </vxe-column>
                        <vxe-column field="content" title="评价" width="20%">
                            <template #default="{row}">
                                <!-- 数值 -->
                                <template v-if="row.type=='4'">
                                    {{row.content?row.content:0}} 分
                                </template>
                                <template v-else>
                                    <vxe-input v-model="row.content" type="number" min="0" :max="row.score"
                                        @change='contentChange(row.content,row.score)' clearable>
                                    </vxe-input>
                                </template>
                            </template>
                        </vxe-column>
                    </vxe-table>
                </el-card>
            </el-col>
        </el-row>
        <div>
            <vxe-modal v-model="changeReivewInfoModel" :position="{top: '0px'}" width="80%" height="100%"
                :show-footer='true' show-zoom resize :transfer='true' >
                <template #title>
                    专家评审信息维护
                </template>
                <el-row :gutter="10" style="height:100%">
                    <el-col :span="6">
                        <el-card class="reviewCustom-box-card modalLeft">
                            <div slot="header">
                                <span>评审类型</span>
                            </div>
                            <el-table :data="reviewTypeList" center class="mytable-scrollbar" stripe style="width: 100%"
                                @row-click='rowClickHandle' :highlight-current-row='true' :show-header='false'>
                                <el-table-column prop="name" label="名称" min-width="200" align="center">
                                </el-table-column>
                                <!-- <el-table-column label="操作" width="200">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="text">删除</el-button>
                                </template>
                            </el-table-column> -->
                            </el-table>
                        </el-card>
                    </el-col>
                    <el-col :span="18">
                        <el-card class="reviewCustom-box-card modalRight">
                            <div slot="header">
                                <span>{{curMark.title}}评审指标</span>
                                <el-button type="text" size="mini" icon="el-icon-plus" style="float:right;"
                                    @click="insertReviewMarkHandle">
                                    添加
                                </el-button>
                            </div>
                            <el-table v-show="curMark.title == '数值型'" :data="numberTypeTable" class="review-detail"
                                stripe style="width: 100%">
                                <el-table-column prop="firstIndex" label="一级指标" width="200"></el-table-column>
                                <el-table-column prop="secondIndex" label="二级指标" width="200"></el-table-column>
                                <el-table-column prop="description" label="注释" width="150"></el-table-column>
                                <el-table-column prop="leftRange" label="权重" width="80"></el-table-column>
                                <el-table-column label="最大值" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.score}} 分
                                    </template>
                                </el-table-column>
                                <el-table-column prop="innerRanking" label="显示排名" width="100"></el-table-column>
                                <el-table-column label="操作" min-width="100">
                                    <template slot-scope="scope">
                                        <el-button size="mini" type="text" @click="editReviewMarkHandle(scope.row)">编辑
                                        </el-button>
                                        <el-popconfirm title="确定删除吗？" @confirm='delReviewMarkHandle(scope.row)'>
                                            <el-button slot="reference" size="mini" type="text"
                                                style="margin-left:10px;">删除
                                            </el-button>
                                        </el-popconfirm>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-table v-show="curMark.title == '单选型'" :data="changeSingleTable"
                                class="mytable-scrollbar" stripe style="width: 100%">
                                <el-table-column prop="firstIndex" label="一级指标" width="200"></el-table-column>
                                <el-table-column prop="secondIndex" label="二级指标" width="200"></el-table-column>
                                <el-table-column prop="description" label="注释" width="200">
                                    <template slot-scope="scope">
                                        <div v-for="item in scope.row.selectValue" :key="item"
                                            style="padding-bottom:5px;">
                                            <el-tooltip class="item" effect="dark" :content="item.description"
                                                placement="top">
                                                <el-tag size="small" v-show="item.description">{{item.description}}
                                                </el-tag>
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="选项值（带红点表示不通过的标准）" min-width="100">
                                    <template slot-scope="scope">
                                        <div v-for="item in scope.row.selectValue" :key="item"
                                            style="padding-bottom:5px;">
                                            <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
                                                <el-badge is-dot :hidden="item.unpassMark!=1" class="item">
                                                    <el-tag size="small">{{item.name}}</el-tag>
                                                </el-badge>
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="innerRanking" label="显示排名" width="100"></el-table-column>
                                <el-table-column label="操作" width="100">
                                    <template slot-scope="scope">
                                        <el-button size="mini" type="text" @click="editReviewMarkHandle(scope.row)">编辑
                                        </el-button>
                                        <el-popconfirm title="确定删除吗？" @confirm='delReviewMarkHandle(scope.row)'>
                                            <el-button slot="reference" size="mini" type="text"
                                                style="margin-left:10px;">删除
                                            </el-button>
                                        </el-popconfirm>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-table v-show="curMark.title == '多选型'" :data="changeMulTable" class="mytable-scrollbar"
                                stripe style="width: 100%">
                                <!-- <el-table-column prop="firstIndex" label="一级指标" width="200"></el-table-column>
                            <el-table-column prop="secondIndex" label="二级指标" width="200"></el-table-column>
                            <el-table-column prop="description" label="注释" width="150"></el-table-column>
                            <el-table-column label="选项值" min-width="200">
                                <template slot-scope="scope">
                                    {{scope.row.selectValue}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="innerRanking" label="显示排名" width="100"></el-table-column>
                            <el-table-column label="操作" width="100">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="text" @click="editReviewMarkHandle(scope.row)">编辑
                                    </el-button>
                                    <el-popconfirm title="确定删除吗？" @confirm='delReviewMarkHandle(scope.row)'>
                                        <el-button slot="reference" size="mini" type="text" style="margin-left:10px;">删除
                                        </el-button>
                                    </el-popconfirm>
                                </template>
                            </el-table-column> -->
                                <el-table-column prop="firstIndex" label="一级指标" width="200"></el-table-column>
                                <el-table-column prop="secondIndex" label="二级指标" width="200"></el-table-column>
                                <el-table-column prop="description" label="注释" width="200">
                                    <template slot-scope="scope">
                                        <div v-for="item in scope.row.selectValue" :key="item"
                                            style="padding-bottom:5px;">
                                            <el-tooltip class="item" effect="dark" :content="item.description"
                                                placement="top">
                                                <el-tag size="small" v-show="item.description">{{item.description}}
                                                </el-tag>
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="选项值" min-width="100">
                                    <template slot-scope="scope">
                                        <div v-for="item in scope.row.selectValue" :key="item"
                                            style="padding-bottom:5px;">
                                            <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
                                                <el-tag size="small">{{item.name}}</el-tag>
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="innerRanking" label="显示排名" width="100"></el-table-column>
                                <el-table-column label="操作" width="100">
                                    <template slot-scope="scope">
                                        <el-button size="mini" type="text" @click="editReviewMarkHandle(scope.row)">编辑
                                        </el-button>
                                        <el-popconfirm title="确定删除吗？" @confirm='delReviewMarkHandle(scope.row)'>
                                            <el-button slot="reference" size="mini" type="text"
                                                style="margin-left:10px;">删除
                                            </el-button>
                                        </el-popconfirm>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-table v-show="curMark.title == '文本型'" :data="textTypeTable" class="mytable-scrollbar"
                                stripe style="width: 100%">
                                <el-table-column prop="firstIndex" label="一级指标" width="200"></el-table-column>
                                <el-table-column prop="secondIndex" label="二级指标" width="200"></el-table-column>
                                <el-table-column prop="description" label="注释" width="150"></el-table-column>
                                <el-table-column label="字数范围" width="100">
                                    <template slot-scope="scope">
                                        {{scope.row.unselectedMark}} - {{scope.row.selectedMark}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="innerRanking" label="显示排名" width="100"></el-table-column>
                                <el-table-column label="操作" min-width="100">
                                    <template slot-scope="scope">
                                        <el-button size="mini" type="text" @click="editReviewMarkHandle(scope.row)">编辑
                                        </el-button>
                                        <el-popconfirm title="确定删除吗？" @confirm='delReviewMarkHandle(scope.row)'>
                                            <el-button slot="reference" size="mini" type="text"
                                                style="margin-left:10px;">删除
                                            </el-button>
                                        </el-popconfirm>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-table v-show="curMark.title == '总分型'" :data="totalTypeTable" class="mytable-scrollbar"
                                stripe style="width: 100%">
                                <el-table-column prop="firstIndex" label="一级指标" width="200"></el-table-column>
                                <el-table-column prop="secondIndex" label="二级指标" width="200"></el-table-column>
                                <el-table-column prop="description" label="注释" width="150"></el-table-column>
                                <el-table-column label="最大值" width="100">
                                    <template slot-scope="scope">
                                        {{scope.row.score}} 分
                                    </template>
                                </el-table-column>
                                <el-table-column label="不通过分数" width="100">
                                    <template slot-scope="scope">
                                        {{scope.row.unpassMark}} 分
                                    </template>
                                </el-table-column>
                                <el-table-column prop="innerRanking" label="显示排名" width="100"></el-table-column>
                                <el-table-column label="操作" min-width="100">
                                    <template slot-scope="scope">
                                        <el-button size="mini" type="text" @click="editReviewMarkHandle(scope.row)">编辑
                                        </el-button>
                                        <el-popconfirm title="确定删除吗？" @confirm='delReviewMarkHandle(scope.row)'>
                                            <el-button slot="reference" size="mini" type="text"
                                                style="margin-left:10px;">删除
                                            </el-button>
                                        </el-popconfirm>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-table v-show="curMark.title == '说明型'" :data="commentTypeTable" class="mytable-scrollbar"
                                stripe style="width: 100%">
                                <el-table-column prop="description" label="说明文本" min-width="200"></el-table-column>
                                <el-table-column prop="innerRanking" label="显示排名" width="100"></el-table-column>
                                <el-table-column label="操作" min-width="100">
                                    <template slot-scope="scope">
                                        <el-button size="mini" type="text" @click="editReviewMarkHandle(scope.row)">编辑
                                        </el-button>
                                        <el-popconfirm title="确定删除吗？" @confirm='delReviewMarkHandle(scope.row)'>
                                            <el-button slot="reference" size="mini" type="text"
                                                style="margin-left:10px;">删除
                                            </el-button>
                                        </el-popconfirm>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-card>
                    </el-col>
                </el-row>
                <template #footer>
                    <el-button @click="cfmInsertReivewHandle" type="primary" size="small">确认</el-button>
                </template>
            </vxe-modal>

            <vxe-modal v-model="typeModel.number" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom
                resize :transfer='true' @hide="numberModelHindHandle">
                <template #title>
                    {{modelIsEdit[curMark.type]?'编辑':'添加'}}数值型评审指标
                </template>
                <vxe-form ref="numberAddRef" :rules="numberValidRules" :data="insertmarks.number" title-align="right" title-width="100" >
                    <vxe-form-item field="firstIndex" title="一级指标" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.firstIndex" placeholder="请输入" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="secondIndex" title="二级指标" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-textarea  v-model="data.secondIndex" placeholder="请输入" size="medium" :autosize="{minRows: 5 }">
                            </vxe-textarea >
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="description" title="注释" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-textarea v-model="data.description" placeholder="请输入" size="medium" :autosize="{minRows: 1 }">
                            </vxe-textarea>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="score" title="最大值" :span="13" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.score" placeholder="请输入" type="number" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="leftRange" title="权重" :span="13" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.leftRange" placeholder="请输入" type="number" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="innerRanking" title="排名" :span="13" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.innerRanking" placeholder="请输入" type="number" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                </vxe-form>
                <template #footer>
                    <el-button @click="insertNumberTypeHandle" type="primary" size="small">确认</el-button>
                </template>
            </vxe-modal>

            <vxe-modal v-model="typeModel.total" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom
                resize :transfer='true' @hide="totalModelHindHandle">
                <template #title>
                    {{modelIsEdit[curMark.type]?'编辑':'添加'}}总分型评审指标
                </template>
                <vxe-form ref="totalRef" :rules="totalValidRules" :data="insertmarks.total" title-align="right" title-width="100">
                    <vxe-form-item field="firstIndex" title="一级指标" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.firstIndex" placeholder="请输入" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="secondIndex" title="二级指标" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.secondIndex" placeholder="请输入" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="description" title="注释" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.description" placeholder="请输入" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="score" title="最大值" :span="13" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.score" placeholder="请输入" type="number" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="unpassMark" title="不通过分数" :span="13" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.unpassMark" placeholder="小于等于改数值表示不通过" type="number" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="innerRanking" title="排名" :span="13" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.innerRanking" placeholder="请输入" type="number" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                </vxe-form>
                <template #footer>
                    <el-button @click="insertTotalTypeHandle" type="primary" size="small">确认</el-button>
                </template>
            </vxe-modal>

            <vxe-modal v-model="typeModel.single" :position="{top: '0px'}" width="1100" :show-footer='true' show-zoom
                resize :transfer='true' @hide="singleModelHindHandle">
                <template #title>
                    {{modelIsEdit[curMark.type]?'编辑':'添加'}}单选型评审指标
                </template>
                <vxe-form ref="singleRef" :rules="singleValidRules" :data="insertmarks.single" title-align="right" title-width="100">
                    <vxe-form-item field="firstIndex" title="一级指标" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.firstIndex" placeholder="请输入" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="secondIndex" title="二级指标" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.secondIndex" placeholder="请输入" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="description" title="注释" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.description" placeholder="请输入" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="selectValue" title="选项值" :span="24" :item-render="{}">
                        <template #default="{ data }">
                            <el-table  :data="data.selectValue" width='100%'>
                                <el-table-column label="选项值" width="200">
                                    <template slot-scope="scope">
                                        <vxe-input v-model="scope.row.name" size="medium" placeholder="请输入">
                                            <template #suffix>
                                                <i class="el-icon-close"
                                                    @click="removeSelectHandle(data.selectValue,scope.$index,scope.row)"></i>
                                            </template>
                                        </vxe-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="注释" width="180">
                                    <template slot-scope="scope">
                                        <vxe-input v-model="scope.row.description" size="medium" placeholder="请输入">
                                        </vxe-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="选中标记"  width="120">
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.selectedMark" allow-create filterable size="small" placeholder="选择">
                                            <el-option v-for="(value,key) in marks" :key="key" :label="key"
                                                :value="value">
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column label="未选中标记" width="120">
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.unselectedMark" allow-create filterable size="small" placeholder="选择">
                                            <el-option v-for="(value,key) in marks" :key="key" :label="key"
                                                :value="value">
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column label="分值范围" width="200">
                                    <template slot-scope="scope">
                                        <vxe-input size="small" v-model="scope.row.leftRange" placeholder="最小值"
                                            type="number" style="width:40%"></vxe-input> -
                                        <vxe-input size="small" v-model="scope.row.rightRange" placeholder="最大值"
                                            type="number" style="width:40%"></vxe-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="不通过标准" width="100">
                                    <template slot-scope="scope">
                                        <vxe-radio-group v-model="scope.row.unpassMark" :strict="false">
                                            <vxe-radio :label="1"></vxe-radio>
                                        </vxe-radio-group>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- <div v-for="(item,index) in data.selectValue">
                            <vxe-input v-model="data.selectValue[index].name" :key='index' size="medium"
                                placeholder="请输入" style="margin-bottom:10px;width:50%;">
                                <template #suffix>
                                    <i class="el-icon-close" @click="removeSelectHandle(data.selectValue,index)"></i>
                                </template>
                            </vxe-input>
                            <vxe-input v-model="data.selectValue[index].unselectedMark" :key='index' size="medium"
                                placeholder="请输入" style="margin-bottom:10px;width:50%;">
                            </vxe-input>
                        </div> -->
                            <vxe-button size="small" content="+" @click="insertSelectHandle(data.selectValue)"
                                style="margin-left:0px;color:green;"></vxe-button>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="innerRanking" title="排名" :span="13" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.innerRanking" placeholder="请输入" type="number" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                </vxe-form>
                <template #footer>
                    <el-button @click="insertSingleTypeHandle" type="primary" size="small">确认</el-button>
                </template>
            </vxe-modal>

            <vxe-modal   v-model="typeModel.mul" :position="{top: '0px'}" width="900" :show-footer='true' show-zoom resize
                :transfer='true' @hide="mulModelHindHandle">
                <template #title>
                    {{modelIsEdit[curMark.type]?'编辑':'添加'}}多选型评审指标
                </template>
                <vxe-form ref="mulRef" :rules="mulValidRules" :data="insertmarks.mul" title-align="right" title-width="100">
                    <vxe-form-item field="firstIndex" title="一级指标" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.firstIndex" placeholder="请输入" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="secondIndex" title="二级指标" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.secondIndex" placeholder="请输入" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="description" title="注释" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.description" placeholder="请输入" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="selectValue" title="选项值" :span="24" :item-render="{}">
                        <template #default="{ data }">
                            <el-table :data="data.selectValue" width='100%'>
                                <el-table-column label="选项值" width="200">
                                    <template slot-scope="scope">
                                        <vxe-input v-model="scope.row.name" size="medium" placeholder="请输入">
                                            <template #suffix>
                                                <i class="el-icon-close"
                                                    @click="removeSelectHandle(data.selectValue,scope.$index)"></i>
                                            </template>
                                        </vxe-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="注释" width="180">
                                    <template slot-scope="scope">
                                        <vxe-input v-model="scope.row.description" size="medium" placeholder="请输入">
                                        </vxe-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="选中标记" width="100">
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.selectedMark"  allow-create filterable  size="small" placeholder="选择">
                                            <el-option v-for="(value,key) in marks" :key="key" :label="key"
                                                :value="value">
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column label="未选中标记" width="100">
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.unselectedMark"  allow-create filterable  size="small" placeholder="选择">
                                            <el-option v-for="(value,key) in marks" :key="key" :label="key"
                                                :value="value">
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column label="分值范围" width="200">
                                    <template slot-scope="scope">
                                        <vxe-input size="small" v-model="scope.row.leftRange" placeholder="最小值"
                                            type="number" style="width:40%"></vxe-input> -
                                        <vxe-input size="small" v-model="scope.row.rightRange" placeholder="最大值"
                                            type="number" style="width:40%"></vxe-input>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <vxe-button size="small" content="+" @click="insertSelectHandle(data.selectValue)"
                                style="margin-left:0px;color:green;"></vxe-button>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="innerRanking" title="排名" :span="13" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.innerRanking" placeholder="请输入" type="number" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                </vxe-form>
                <template #footer>
                    <el-button @click="insertMulTypeHandle" type="primary" size="small">确认</el-button>
                </template>
            </vxe-modal>

            <vxe-modal v-model="typeModel.text" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom
                resize :transfer='true' @hide="textModelHindHandle">
                <template #title>
                    {{modelIsEdit[curMark.type]?'编辑':'添加'}}文本型评审指标
                </template>
                <vxe-form ref="textRef" :rules="textValidRules" :data="insertmarks.text" title-align="right" title-width="100">
                    <vxe-form-item field="firstIndex" title="一级指标" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.firstIndex" placeholder="请输入" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="secondIndex" title="二级指标" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.secondIndex" placeholder="请输入" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="description" title="注释" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.description" placeholder="请输入" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="unselectedMark" title="最小字数" :span="13" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.unselectedMark" placeholder="请输入" type="number" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="selectedMark" title="最大字数" :span="13" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.selectedMark" placeholder="请输入" type="number" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="innerRanking" title="排名" :span="13" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.innerRanking" placeholder="请输入" type="number" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                </vxe-form>
                <template #footer>
                    <el-button @click="insertTextTypeHandle" type="primary" size="small">确认</el-button>
                </template>
            </vxe-modal>

            <vxe-modal v-model="typeModel.comment" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom
                resize :transfer='true' @hide="commentModelHindHandle">
                <template #title>
                    {{modelIsEdit[curMark.type]?'编辑':'添加'}}说明型评审指标
                </template>
                <vxe-form ref="commentRef" :rules="commentValidRules" :data="insertmarks.comment" title-align="right" title-width="100">
                    <vxe-form-item field="description" title="说明文本" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.description" placeholder="请输入" size="medium">
                            </vxe-input>
                            <!-- <vxe-textarea v-model="data.description" placeholder="请输入"></vxe-textarea> -->
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="innerRanking" title="排名" :span="13" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.innerRanking" placeholder="请输入" type="number" size="medium">
                            </vxe-input>
                        </template>
                    </vxe-form-item>
                </vxe-form>
                <template #footer>
                    <el-button @click="insertCommentTypeHandle" type="primary" size="small">确认</el-button>
                </template>
            </vxe-modal>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ExpertReviewCustom',
        data() {
            return {
                changeReivewInfoModel: false,
                patternId: '',
                insertReviewModel: false,
                insertReviewData: {
                    name: '',
                    isCopy: 0,
                    patternId: ''
                },
                typeModel: {
                    number: false,
                    total: false,
                    single: false,
                    mul: false,
                    text: false,
                    comment: false
                },
                modelIsEdit: {
                    number: false,
                    total: false,
                    single: false,
                    mul: false,
                    text: false,
                    comment: false
                },
                typeModelMap: {
                    '数值型': 'number',
                    '总分型': 'total',
                    '单选型': 'single',
                    '多选型': 'mul',
                    '文本型': 'text',
                    '说明型': 'comment'
                },
                // marks: {
                //     numberMarks: {}
                // },
                search: '',
                reviewList: [{
                    name: '南京理工大学计算机学院',
                }],
                insertReviewType: {
                    type: ''
                },
                types: ['数值型', '总分型', '单选型', '多选型', '文本型', '说明型'],
                reviewTypeList: [{
                    name: '数值型'
                }, {
                    name: '单选型'
                }, {
                    name: '多选型'
                }, {
                    name: '总分型'
                }, {
                    name: '文本型'
                }, {
                    name: '说明型'
                }],
                insertmarks: {
                    number: {
                        id: '',
                        firstIndex: '一级指标名称',
                        secondIndex: '二级指标名称',
                        description: '注释',
                        score: '15',
                        innerRanking: '1',
                        leftRange: 1
                    },
                    total: {
                        id: '',
                        firstIndex: '一级指标名称',
                        secondIndex: '二级指标名称',
                        description: '注释',
                        score: '100',
                        innerRanking: 1
                    },
                    single: {
                        id: '',
                        firstIndex: '一级指标名称',
                        secondIndex: '二级指标名称',
                        description: '注释',
                        selectValue: [],
                        innerRanking: 1
                    },
                    mul: {
                        id: '',
                        firstIndex: '一级指标名称',
                        secondIndex: '二级指标名称',
                        description: '注释',
                        selectValue: [{
                                name: '优秀',
                                id: ''
                            },
                            {
                                name: '良好',
                                id: ''
                            }, {
                                name: '及格',
                                id: ''
                            }, {
                                name: '不及格',
                                id: ''
                            }
                        ],
                        innerRanking: 1
                    },
                    text: {
                        id: '',
                        firstIndex: '一级指标名称',
                        secondIndex: '二级指标名称',
                        description: '注释',
                        unselectedMark: 300,
                        selectedMark: 3000,
                        innerRanking: 1
                    },
                    comment: {
                        id: '',
                        description: '注释文本',
                        innerRanking: 1
                    }
                },
                numberTypeTable: [{
                    first: '一级指标名称',
                    second: '二级指标名称',
                    comment: '注释',
                    score: '15',
                    rank: '1'
                }],
                singleSelectTypeTable: [{
                    first: '一级指标名称',
                    second: '二级指标名称',
                    comment: '注释',
                    selectValue: ['优秀', '良好', '及格', '不及格'],
                    rank: 1
                }],
                mulSelectTypeTable: [{
                    first: '一级指标名称',
                    second: '二级指标名称',
                    comment: '注释',
                    selectValue: ['选项1', '选项2', '选项3', '选项4'],
                    rank: 1
                }],
                textTypeTable: [{
                    first: '一级指标名称',
                    second: '二级指标名称',
                    comment: '注释',
                    unselectedMark: 300,
                    selectedMark: 3000,
                    rank: 1
                }],
                totalTypeTable: [{
                    first: '总分',
                    second: '总分',
                    comment: '注释',
                    score: '0',
                    rank: 1
                }],
                commentTypeTable: [{
                    comment: '说明文本说明文本说明文本说明文本说明文本说明文本说明文本说明文本说明文本说明文本',
                    rank: 1
                }],
                curMark: {
                    title: '数值型',
                    type: 'number'
                },
                singleSelects: {},
                groups: [],
                ranks: [],
                rawRank: -2,
                // 对象
                scoreIndex: {},
                dataTable: [],
                // 数值型
                numberValidRules:{
                    firstIndex: [
                        { required: true, message: '请输入一级指标' },
                    ],
                    score: [
                        { required: true, message: '请输入最大值' },
                    ],
                    leftRange: [
                        { required: true, message: '请输入权重' },
                    ],
                    innerRanking: [
                        { required: true, message: '请输入排名' },
                    ]
                },
                // 总分型
                totalValidRules:{
                    firstIndex: [
                        { required: true, message: '请输入一级指标' },
                    ],
                    score: [
                        { required: true, message: '请输入最大值' },
                    ],
                    innerRanking: [
                        { required: true, message: '请输入排名' },
                    ]
                },
                // 说明型
                commentValidRules:{
                    description: [
                        { required: true, message: '请输入说明文本' },
                    ],

                    innerRanking: [
                        { required: true, message: '请输入排名' },
                    ]
                },
                // 单选型
                singleValidRules:{
                    firstIndex: [
                        { required: true, message: '请输入一级指标' },
                    ],
                    selectValue: [
                        { required: true, message: '请填写至少一个选项值' },
                    ],

                    innerRanking: [
                        { required: true, message: '请输入排名' },
                    ]
                },
                // 多选型
                mulValidRules:{
                    firstIndex: [
                        { required: true, message: '请输入一级指标' },
                    ],
                    selectValue: [
                        { required: true, message: '请填写至少一个选项值' },
                    ],
                    innerRanking: [
                        { required: true, message: '请输入排名' },
                    ]
                },
                // 文本型
                textValidRules:{
                    firstIndex: [
                        { required: true, message: '请输入一级指标' },
                    ],
                    unselectedMark: [
                        { required: true, message: '请输入最小字数' },
                    ],
                    selectedMark: [
                        { required: true, message: '请输入最大字数' },
                    ],
                    innerRanking: [
                        { required: true, message: '请输入排名' },
                    ]
                },
            }
        },
        created() {
            this.getAllPatterns();
        },
        computed: {
            marks() {
                console.log(this.$store.state.mapper.marks);
                return this.$store.state.mapper.marks;
            },
            changeSingleTable() {
                let arr = {};
                this.singleSelectTypeTable.forEach(item => {
                    let group = item.group;
                    if (arr.hasOwnProperty(group)) {
                        arr[group].selectValue.push({
                            id: item.id,
                            name: item.score,
                            unselectedMark: item.unselectedMark,
                            selectedMark: item.selectedMark,
                            leftRange: item.leftRange,
                            rightRange: item.rightRange,
                            description: item.description,
                            unpassMark:item.unpassMark
                        });
                    } else {
                        arr[group] = item;
                        arr[group].innerRanking = parseInt(item.innerRanking / 100);
                        arr[group]['selectValue'] = [{
                            id: item.id,
                            name: item.score,
                            unselectedMark: item.unselectedMark,
                            selectedMark: item.selectedMark,
                            leftRange: item.leftRange,
                            rightRange: item.rightRange,
                            description: item.description,
                            unpassMark:item.unpassMark
                        }];
                    }
                })
                return Object.values(arr);
            },
            changeMulTable() {
                let arr = {};
                this.mulSelectTypeTable.forEach(item => {
                    // let group = item.group;
                    // if (arr.hasOwnProperty(group)) {
                    //     arr[group].selectValue.push(item.score);
                    // } else {
                    //     arr[group] = item;
                    //     arr[group]['selectValue'] = [item.score];
                    // }
                    let group = item.group;
                    if (arr.hasOwnProperty(group)) {
                        arr[group].selectValue.push({
                            id: item.id,
                            name: item.score,
                            unselectedMark: item.unselectedMark,
                            selectedMark: item.selectedMark,
                            leftRange: item.leftRange,
                            rightRange: item.rightRange,
                            description: item.description
                        });
                    } else {
                        arr[group] = item;
                        arr[group].innerRanking = parseInt(item.innerRanking / 100);
                        arr[group]['selectValue'] = [{
                            id: item.id,
                            name: item.score,
                            unselectedMark: item.unselectedMark,
                            selectedMark: item.selectedMark,
                            leftRange: item.leftRange,
                            rightRange: item.rightRange,
                            description: item.description
                        }];
                    }
                })
                return Object.values(arr);
            }
        },
        methods: {
            handleCurrentChange(currentRow) {
              // 处理当前选中行变化
              this.currentRow = currentRow;
            },
            changeSignature(row) {
                this.postEditPattern(row);
            },
            getAllPatterns() {
                this.$api.reviewCustom.getAllPatterns()
                    .then(res => {
                        if (res.data.code == 200) {
                            this.reviewList = res.data.data;
                            let arr = [];
                            res.data.data.forEach(item => {
                                item.isSignature = item.isSignature ? true : false;
                                arr.push(item);
                            });
                            this.reviewList = arr;
                            this.getSinglePattern(this.reviewList[0].id);
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            // 修改
            postEditPattern(row) {
                let params = {
                    id: row.id,
                    patternName: row.patternName,
                    patternFile: row.patternFile,
                    isSignature: row.isSignature ? 1 : 0
                };
                let msg = row.isSignature ? "开启" : "关闭";
                this.$api.reviewCustom.postEditPattern(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success(msg + "成功！");
                        } else {
                            this.$message.warning(msg + "失败！");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            // 创建一条评审体系
            insertReviewHandle() {
                this.insertReviewData = {
                    name: '',
                    isCopy: 0,
                    patternId: ''
                }
                this.insertReviewModel = true;
            },
            cfmInsertReivewNameHandle() {
                if (this.insertReviewData.name == '') {
                    this.$message.warning("请输入名称");
                    return;
                }

                if (this.insertReviewData.isCopy == '1') {
                    if (this.insertReviewData.patternId == '') {
                        this.$message.warning("请选择模板");
                        return;
                    }
                    let params = {
                        patternId: this.insertReviewData.patternId,
                        patternName: this.insertReviewData.name,
                    };
                    this.$api.reviewCustom.copyReviewSystemAndTemplate(params)
                        .then(res => {
                            if (res.data.data == 200) {
                                this.$message.success('创建成功！');
                                this.getAllPatterns();
                                this.insertReviewModel = false;
                            }
                        }).catch(err => {
                            this.$message.warning("服务器维护！");
                        })
                } else {
                    let params = {
                        id: '',
                        signature: 0,
                        patternName: this.insertReviewData.name,
                        patternFile: ''
                    };
                    this.$api.reviewCustom.postAddPattern(params)
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$message.success('创建成功！');
                                this.getAllPatterns();
                                this.insertReviewModel = false;
                            }
                        }).catch(err => {
                            this.$message.warning("服务器维护！");
                        })
                }

            },
            // 删除评审体系
            postDisablePattern(id) {
                let params = {
                    patternId: id
                };
                this.$api.reviewCustom.postDisablePattern(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success('删除成功！');
                            this.getAllPatterns();
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            changeSingle() {
                let groupSelected = {};
                this.singleSelectTypeTable.forEach(item => {
                    if (!groupSelected.hasOwnProperty(item.group) || groupSelected[item
                            .group] == '') {
                        groupSelected[item.group] = '';
                    }
                });
                this.singleSelects = groupSelected;
            },
            changeGroup() {
                this.groups = [];
                this.numberTypeTable.forEach(item => {
                    this.groups.push(item.group);
                });
                this.singleSelectTypeTable.forEach(item => {
                    this.groups.push(item.group);
                });
                this.mulSelectTypeTable.forEach(item => {
                    this.groups.push(item.group);
                });
                this.totalTypeTable.forEach(item => {
                    this.groups.push(item.group);
                });
                this.textTypeTable.forEach(item => {
                    this.groups.push(item.group);
                });
                this.commentTypeTable.forEach(item => {
                    this.groups.push(item.group);
                });
            },
            getGroup() {
                let i = 1;
                while (i < 10000) {
                    if (this.groups.indexOf(i) == -1) {
                        return i;
                    }
                    i++;
                }
                return 0;
            },
            changeRanks() {
                let ranks = [];
                this.numberTypeTable.forEach(item => {
                    ranks.push(item.innerRanking);
                });
                this.singleSelectTypeTable.forEach(item => {
                    let innerRanking = item.innerRanking / 100;
                    if (ranks.indexOf(innerRanking) == -1) {
                        ranks.push(innerRanking);
                    }
                });
                this.mulSelectTypeTable.forEach(item => {
                    let innerRanking = item.innerRanking / 100;
                    if (ranks.indexOf(innerRanking) == -1) {
                        ranks.push(innerRanking);
                    }
                });
                this.totalTypeTable.forEach(item => {
                    ranks.push(item.innerRanking);
                });
                this.textTypeTable.forEach(item => {
                    ranks.push(item.innerRanking);
                });
                this.commentTypeTable.forEach(item => {
                    ranks.push(item.innerRanking);
                });
                this.ranks = ranks;

            },
            getSinglePattern(id) {
                let params = {
                    patternId: id
                };
                this.$api.reviewCustom.getSinglePattern(params)
                    .then(res => {
                        if (res.data.code == 200) {
                          console.log('table', res.data.data)
                            this.numberTypeTable = res.data.data.details.NUM;
                            this.singleSelectTypeTable = res.data.data.details.SIG_OPT_MULTI;
                            this.mulSelectTypeTable = res.data.data.details.MULTI_OPT;
                            this.totalTypeTable = res.data.data.details.SUM;
                            this.textTypeTable = res.data.data.details.TEXT;
                            this.commentTypeTable = res.data.data.details.DES;
                            this.changeSingle();
                            this.changeGroup();
                            this.changeRanks();
                            this.changeDataTable();
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            // 合并数值单选特征
            changeDataTable() {
                let arr = [];
                this.numberTypeTable.forEach((item, index) => {
                    this.$set(this.numberTypeTable[index], 'content', '');
                    arr.push(this.numberTypeTable[index]);
                });
                this.totalTypeTable.forEach((item, index) => {
                    this.$set(this.totalTypeTable[index], 'content', '');
                    arr.push(this.totalTypeTable[index]);
                });
                this.singleSelectTypeTable.forEach((item, index) => {
                    this.$set(this.singleSelectTypeTable[index], 'content', '');
                    arr.push(this.singleSelectTypeTable[index]);
                });
                this.mulSelectTypeTable.forEach((item, index) => {
                    this.$set(this.mulSelectTypeTable[index], 'content', '');
                    arr.push(this.mulSelectTypeTable[index]);
                });
                this.textTypeTable.forEach((item, index) => {
                    this.$set(this.textTypeTable[index], 'content', '');
                    arr.push(this.textTypeTable[index]);
                });
                this.commentTypeTable.forEach((item, index) => {
                    this.$set(this.commentTypeTable[index], 'content', '');
                    arr.push(this.commentTypeTable[index]);
                })
                let n = arr.length;
                for (let i = 0; i < n; i++) {
                    for (let j = i + 1; j < n; j++) {
                        let irank = arr[i].innerRanking;
                        let jrank = arr[j].innerRanking;
                        if (irank >= 100) irank /= 100;
                        if (jrank >= 100) jrank /= 100;

                        if (irank >= jrank) {
                            let t = arr[i];
                            arr[i] = arr[j];
                            arr[j] = t;
                        }
                    }
                };
                this.dataTable = arr;
            },
            content(score, leftRange, rightRange) {
                let content = score;
                if (leftRange == 0 && rightRange == 100) return content;
                if (!rightRange) return content;
                content += '（' + leftRange + '-' + rightRange + ' 分' + '）';
                return content;
            },
            contentChange(
                value, maxn
            ) {
                if (this.totalScore < 0) return;
                if (Number(value) < 0) return;
                if (Number(value) > Number(maxn)) return;
                let score = 0;
                this.numberTypeTable.forEach(item => {
                    score += Number(item.content) * Number(item.leftRange);
                });
                this.totalTypeTable[0].content = score;
                for (let key in this.singleSelectTypeTable) {
                    // console.log(this.singleSelects[key]);
                    if (this.singleSelects[key]) {
                        let index = this.scoreIndex[this.singleSelects[key]];
                        let l = this.singleSelectTypeTable[index].leftRange;
                        let r = this.singleSelectTypeTable[index].rightRange;
                        // console.log(l,r);
                        if (!(score >= l && score <= r)) this.singleSelects[key] = '';
                    }
                }
            },
            changeReviewInfoHandle(id) {
                this.patternId = id;
                // this.resetTable();
                this.getSinglePattern(id);
                this.changeReivewInfoModel = true;
            },
            cfmInsertReivewHandle() {
                this.changeReivewInfoModel = false;
            },
            rowClickHandle(row, column, event) {
                this.curMark.title = row.name;
                this.curMark = {
                    title: row.name,
                    type: this.typeModelMap[row.name]
                }
            },
            reviewRowClickHandle(row, column, event) {
                this.getSinglePattern(row.id);
                this.currentRow = row;
            },
            judgeInType(str) {
                for (let i = 0; i < this.reviewTypeList.length; i++) {
                    if (this.reviewTypeList[i].name == str) return true;
                }
                console.log(false);
                return false;
            },
            // 添加选项值
            insertSelectHandle(selectValue) {
                selectValue.push({
                    name: '',
                    id: '',
                    leftRange: '',
                    rightRange: '',
                    description: ''
                });
            },
            // 删除选项值
            removeSelectHandle(selectValue, index, row) {
                console.log('单项删除selectValue', selectValue)
                console.log('单项删除index', index)
                selectValue.splice(index, 1);
                let params = {
                  detailId: row.id
                }
                console.log('单项删除id', params)
                this.$api.reviewCustom.postDisableDetailById(params)
                    .then(res => {
                      if (res.data.code == 200) {
                        // console.log('删除成功');
                        this.$message.success("删除成功！");
                        // this.getSinglePattern(this.patternId);
                      }
                    }).catch(err => {
                  this.$message.warning("服务器维护！");
                })
                // this.getSinglePattern(this.patternId);
            },
            insertReviewMarkHandle() {
                this.insertmarks[this.curMark.type] = {
                    id: '',
                    firstIndex: '',
                    secondIndex: '',
                    patternId: '',
                    innerRanking: '',
                    score: '',
                    type: '',
                    group: '',
                    description: '',
                    selectedMark: '',
                    unselectedMark: '',
                    selectValue: [],
                    leftRange: '',
                    rightRange: '',
                };
                if (this.curMark.type == 'number') this.insertmarks[this.curMark.type].leftRange = 1;
                this.modelIsEdit[this.curMark.type] = false;
                this.typeModel[this.curMark.type] = true;
            },
            validateInnerRanks(rank) {
                console.log(this.ranks, rank, this.ranks.indexOf(rank), this.rawRank)
                if (this.ranks.indexOf(Number(rank)) == -1 || Number(rank) == this.rawRank) return true;
                this.$message.warning("此排名已存在");
                return false;
            },
            postEditDetails(params) {
                this.$api.reviewCustom.postEditDetails(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            if (this.modelIsEdit[this.curMark.type]) this.$message.success("修改成功！");
                            else this.$message.success("添加成功！");
                            this.getSinglePattern(this.patternId);
                            this.typeModel[this.curMark.type] = false;
                            this.ranks = [];
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            async insertNumberTypeHandle() {
                let isValid = await this.$refs.numberAddRef.validate();
                // console.log(isValid)
                if(isValid) return;
                // console.log("校验成功");
                if (!this.validateInnerRanks(this.insertmarks.number.innerRanking)) return;
                this.rawRank = -2;
                let params = [{
                    id: this.insertmarks.number.id,
                    firstIndex: this.insertmarks.number.firstIndex,
                    secondIndex: this.insertmarks.number.secondIndex,
                    patternId: this.patternId,
                    innerRanking: this.insertmarks.number.innerRanking,
                    score: this.insertmarks.number.score,
                    type: 0,
                    group: this.getGroup(),
                    description: this.insertmarks.number.description,
                    selectedMark: '',
                    unselectedMark: '',
                    leftRange: this.insertmarks.number.leftRange,
                    rightRange: ''
                }];
                this.postEditDetails(params);
            },
            async insertTotalTypeHandle() {
                let isValid = await this.$refs.totalRef.validate();
                console.log(isValid);
                if(isValid) return;
                console.log("成功");
                if (!this.validateInnerRanks(this.insertmarks.total.innerRanking)) return;
                this.rawRank = -2;
                let params = [{
                    id: this.insertmarks.total.id,
                    firstIndex: this.insertmarks.total.firstIndex,
                    secondIndex: this.insertmarks.total.secondIndex,
                    patternId: this.patternId,
                    innerRanking: this.insertmarks.total.innerRanking,
                    score: this.insertmarks.total.score,
                    type: 4,
                    group: this.getGroup(),
                    description: this.insertmarks.total.description,
                    selectedMark: '',
                    unselectedMark: '',
                    leftRange: '',
                    rightRange: '',
                    unpassMark:this.insertmarks.total.unpassMark
                }];
                this.postEditDetails(params);
            },
            async insertSingleTypeHandle() {
                // 校验基本数据
                let isValid = await this.$refs.singleRef.validate();
                if(isValid) return;
                // 校验每个选项值中的数据，数据值、选中标记、未选中标记不能为空
                for(let i = 0;i<this.insertmarks.single.selectValue.length;i++){
                    let singleItem = this.insertmarks.single.selectValue[i];
                    console.log(singleItem)
                    // 刚加入时selectedMark与 unselectedMark不存在，所以通过判断该属性是否存在可以判断是否添加了选项值。
                    // 此外，选项值可以为空‘’，无需判断是否为空。
                    if(singleItem.name == '' || singleItem.selectedMark==undefined || singleItem.unselectedMark==undefined){
                        this.$message.warning("数据值、选中标记、未选中标记不能为空！");
                        return false;
                    }
                }
                // 校验排名
                if (!this.validateInnerRanks(this.insertmarks.single.innerRanking)) return;
                this.rawRank = -2;
                let params = [];
                this.insertmarks.single.selectValue.forEach((item, index) => {
                    console.log(item);
                    params.push({
                        id: item.id,
                        firstIndex: this.insertmarks.single.firstIndex,
                        secondIndex: this.insertmarks.single.secondIndex,
                        patternId: this.patternId,
                        innerRanking: this.insertmarks.single.innerRanking * 100 + index,
                        score: item.name,
                        type: 2,
                        group: this.getGroup(),
                        description: item.description,
                        selectedMark: item.selectedMark,
                        unselectedMark: item.unselectedMark,
                        leftRange: item.leftRange,
                        rightRange: item.rightRange,
                        unpassMark:item.unpassMark == true?1:0
                    })
                });
                this.postEditDetails(params);
            },
            async insertMulTypeHandle() {
                // 校验基本数据
                let isValid = await this.$refs.mulRef.validate();
                if(isValid) return;

                // 校验每个选项值中的数据，数据值、选中标记、未选中标记不能为空
                for(let i = 0;i<this.insertmarks.mul.selectValue.length;i++){
                    let singleItem = this.insertmarks.mul.selectValue[i];
                    console.log(singleItem);
                    // 刚加入时selectedMark与 unselectedMark不存在，所以通过判断该属性是否存在可以判断是否添加了选项值。
                    // 此外，选项值可以为空‘’，无需判断是否为空。
                    if(singleItem.name == '' || singleItem.selectedMark==undefined || singleItem.unselectedMark==undefined){
                        this.$message.warning("数据值、选中标记、未选中标记不能为空！");
                        return false;
                    }
                }
                // 校验排名
                if (!this.validateInnerRanks(this.insertmarks.mul.innerRanking)) return;
                console.log(this.insertmarks.mul);
                this.rawRank = -2;
                let params = [];
                this.insertmarks.mul.selectValue.forEach((item, index) => {
                    params.push({
                        id: item.id,
                        firstIndex: this.insertmarks.mul.firstIndex,
                        secondIndex: this.insertmarks.mul.secondIndex,
                        patternId: this.patternId,
                        innerRanking: this.insertmarks.mul.innerRanking * 100 + index,
                        score: item.name,
                        type: 3,
                        group: this.getGroup(),
                        description: item.description,
                        selectedMark: item.selectedMark,
                        unselectedMark: item.unselectedMark,
                        leftRange: item.leftRange,
                        rightRange: item.rightRange
                    })
                })
                this.postEditDetails(params);
            },
            async insertTextTypeHandle() {
                let isValid = await this.$refs.textRef.validate();
                if(isValid) return;
                if (!this.validateInnerRanks(this.insertmarks.text.innerRanking)) return;
                this.rawRank = -2;
                let params = [{
                    id: this.insertmarks.text.id,
                    firstIndex: this.insertmarks.text.firstIndex,
                    secondIndex: this.insertmarks.text.secondIndex,
                    patternId: this.patternId,
                    innerRanking: this.insertmarks.text.innerRanking,
                    score: this.insertmarks.text.score,
                    type: 5,
                    group: this.getGroup(),
                    description: this.insertmarks.text.description,
                    selectedMark: this.insertmarks.text.selectedMark,
                    unselectedMark: this.insertmarks.text.unselectedMark,
                    leftRange: '',
                    rightRange: ''
                }];
                this.postEditDetails(params);
            },
            async insertCommentTypeHandle() {
                let isValid = await this.$refs.commentRef.validate();
                if(isValid) return;
                if (!this.validateInnerRanks(this.insertmarks.comment.innerRanking)) return;
                this.rawRank = -2;
                let group = this.getGroup();
                let params = [{
                    id: this.insertmarks.comment.id,
                    firstIndex: '',
                    secondIndex: '',
                    patternId: this.patternId,
                    innerRanking: this.insertmarks.comment.innerRanking,
                    score: '',
                    type: 6,
                    group: '',
                    description: this.insertmarks.comment.description,
                    selectedMark: '',
                    unselectedMark: '',
                    leftRange: '',
                    rightRange: ''
                }];
                this.postEditDetails(params);
            },
            editReviewMarkHandle(row) {
                console.log(row);
                this.modelIsEdit[this.curMark.type] = true;
                this.typeModel[this.curMark.type] = true;
                let key = this.typeModelMap[this.curMark.title];
                this.rawRank = row.innerRanking;
                this.insertmarks[key] = JSON.parse(JSON.stringify(row));
            },
            delReviewMarkHandle(row) {
                let params = {
                    detailId: row.id
                }
                this.$api.reviewCustom.postDisableDetail(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            console.log('删除成功');
                            this.$message.success("删除成功！");
                            this.getSinglePattern(this.patternId);
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
                this.getSinglePattern(this.patternId);
            },
            //合并表格
            mergeMethod({
                row,
                _rowIndex,
                column,
                _columnIndex,
                visibleData
            }) {
                let firstIndex = row['firstIndex'];
                let type = row['type'];
                // 总分型
                if (row.type == '4') {
                    let rows = 1,
                        cols = 1;
                    if (column.property == 'firstIndex') {
                        rows = 1;
                        cols = 2;
                    } else if (_columnIndex == 1) {
                        rows = 0;
                        cols = 0;
                    } else {
                        rows = 1;
                        cols = 1;
                    }
                    return {
                        rowspan: rows,
                        colspan: cols
                    }
                }
                if (row.type == '5' || row.type == '6') {
                    let rows = 1,
                        cols = 1;
                    if (column.property == 'firstIndex') {
                        rows = 1;
                        cols = 3;
                    } else {
                        rows = 0;
                        cols = 0;
                    }
                    return {
                        rowspan: rows,
                        colspan: cols
                    }
                }
                if (column.property == 'firstIndex') {
                    let prevRow = visibleData[_rowIndex - 1];
                    let nextRow = visibleData[_rowIndex + 1];
                    if (prevRow && prevRow['firstIndex'] == firstIndex) {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    } else {
                        // 一级指标相同合并 从第一行合并到第4行
                        let countRowspan = 1;
                        let countColspan = 1;
                        while (nextRow && nextRow['firstIndex'] == firstIndex && nextRow['type'] == type) {
                            nextRow = visibleData[++countRowspan + _rowIndex]
                        }
                        // if (row.type == 5 && _columnIndex == 1) {
                        //     countColspan = 2;
                        // }
                        if (countRowspan >= 1) {
                            return {
                                rowspan: countRowspan,
                                colspan: countColspan
                            };
                        }
                    }
                }
                // 单选型
                if (row.type == '2' || row.type == '3') {
                    let rows = 1,
                        cols = 1;
                    if (_columnIndex == 1) {
                        rows = 1;
                        cols = 2;
                    } else {
                        rows = 0;
                        cols = 0;
                    }
                    return {
                        rowspan: rows,
                        colspan: cols
                    }
                }
            },
            // 表格样式
            cellStyleNumber({
                row,
                rowIndex,
                column,
                columnIndex
            }, tableData) {
                //设置状态颜色
                let styles = {};
                if (row.type == '5' || row.type == '6') {
                    styles['padding'] = '0px';
                }
                if ((row.type == '2' || row.type == '3') && columnIndex == 1) {
                    styles['text-align'] = 'left';
                }
                if (columnIndex == 0) {
                    styles['border-bottom'] = '1px solid rgb(153, 153, 153)';
                }
                if (rowIndex == this.dataTable.length - 1) {
                    styles['border-bottom'] = '1px solid rgb(153, 153, 153)';
                    return styles;
                }
                if (this.dataTable[rowIndex].type != this.dataTable[rowIndex + 1].type || this.dataTable[rowIndex]
                    .firstIndex != this.dataTable[rowIndex + 1].firstIndex) {
                    styles['border-bottom'] = '1px solid rgb(153, 153, 153)';
                }
                return styles;
            },
            cellClassName({
                row,
                column
            }) {
                if (row.type == '5' || row.type == '6') {
                    return 'mycell';
                }
            },
            numberModelHindHandle(){
                this.$refs.numberAddRef.clearValidate();
            },
            singleModelHindHandle(){
                this.$refs.singleRef.clearValidate();
            },
            mulModelHindHandle(){
                this.$refs.mulRef.clearValidate();
            },
            totalModelHindHandle(){
                this.$refs.totalRef.clearValidate();
            },
            textModelHindHandle(){
                this.$refs.textRef.clearValidate();
            },
            commentModelHindHandle(){
                this.$refs.commentRef.clearValidate();
            },
        },
        components: {

        }
    }
</script>

<style scoped>
    /* .reviewCustom-box-card {
        height: calc(100vh - 100px);
    } */

    .left {
        /* width: 600px; */
        height: calc(100vh - 92px);
        padding: 0px;
        overflow: auto;
    }

    .modalLeft {
        height: calc(100vh - 120px);
    }

    .modalRight {
        height: calc(100vh - 120px);
    }

    .left::-webkit-scrollbar {
        width: 0 !important
    }

    .right {
        /* min-width: 600px; */
        /* margin-left: 20px; */
        height: calc(100vh - 92px);
        overflow: auto;
    }

    .right::-webkit-scrollbar {
        width: 0 !important
    }

    /* .review-detail{
        overflow: auto;
    }
    .review-detail::-webkit-scrollbar {
        width: 0 !important
    } */

    .txtareaCom {
        border: 1px solid rgb(143, 143, 143);
        border-top: 0px;
        padding: 10px;
        color: rgb(160, 160, 160);
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
        font-size: 14px;
    }
</style>

<style>
    .el-card__body {
        padding: 0px !important;
    }

    .modalLeft .el-card__body {
        height: 100%;
        overflow: auto;
    }

    .modalRight .el-card__body {
        height: 90%;
        overflow: auto;
    }

    .el-card__header {
        padding: 15px !important;
    }

    .reviewCustom-box-card .el-form-item {
        margin-bottom: 0px !important;
    }

    .modalLeft .el-table__body-wrapper {
        height: 100%;
        overflow: auto;
    }

    .mycell .vxe-cell {
        padding: 0px;
        text-align: left;
    }
</style>